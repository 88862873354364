import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"


const SpringSummer24 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
        "lure-90-nappa-sandals",
        "forever-85-nappa-pumps",
        "siren-85-nappa-pvc-pumps",
        "stow-10-nappa-flats",
        "stow-stud-10-nappa-flats-1",
        "balanced-10-nappa-flats-1",
        "balanced-stud-10-nappa-flats",
        "desire-65-nappa-pumps-1",
        "futura-65-nappa-sandals",
        "solitude-65-nappa-laminata-sandals-1",
        "stares-35-python-print-sandals",
        "cougar-10-python-print-flats",
        "absolute-85-nappa-sandals",
        "absolute-40-nappa-laminata-sandals-1",
        "solar-85-nappa-laminata-sandals",
        "solar-65-nappa-laminata-sandals-1",
        "luster-40-nappa-laminata-sandals-1",
        "console-lug-nappa-laminata-flats",
        "dwell-suede-flats",
        "oriel-05-lucertolina-flats",
        "alpha-85-naplack-sandals",
        "ardor-55-naplack-sandals",
        "midnight-05-naplack-sandals",
        "reverse-wedge-90-nappa-sandals",
        "reverse-sandal-75-nappa-sandals",
        "flatline-10-nappa-laminata-flats",
        "telegraphic-65-suede-sandals",
        "dusk-05-suede-flats",
        "dream-catcher-90-suede-sandals",
        "dream-maker-10-suede-flats",
        "solstice-65-suede-sandals",
        "solstice-10-nappa-laminata-flats",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <div className={styles.ss22}>
      <SEO
        title="Spring/Summer '24"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/HP_SS_Hero_Lure_desktop_2000x1125_e7dd156c-6e8a-4019-9226-bf03a75ba21c.jpg?v=1716513773",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/HP_SS_Hero_Lure_mobile_846x476_c4bb37a1-9f23-4f0b-a204-f170fdf4dae4.jpg?v=1716513772",
          }}
          altText="Spring/Summer '24 Image"
          className={styles.imgFullWidth}
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.headingDark}
        >
          Spring/Summer '24
        </Heading>
      </header>

      <ul className={styles.grid1ss24}>
        {allSkus.filter(sku => [
          "lure-90-nappa-sandals",
          "forever-85-nappa-pumps",
          "siren-85-nappa-pvc-pumps",
          "stow-10-nappa-flats",
          "stow-stud-10-nappa-flats-1",
          "balanced-10-nappa-flats-1",
          "balanced-stud-10-nappa-flats",
          "desire-65-nappa-pumps-1",
          "futura-65-nappa-sandals",
          "solitude-65-nappa-laminata-sandals-1",
          "stares-35-python-print-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss24`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/HP_SS_sub_Dream_Maker_desktop_1320x854_41064bc4-c21d-4780-a2be-f6971df45d95.jpg?v=1716513845",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/HP_SS_Dream_Maker_mobile_850x550_46231280-4f42-4340-b11b-e8724156413a.jpg?v=1716513845",
        }}
        altText="Spring/Summer '24 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss24}>
        {allSkus.filter(sku => [
          "cougar-10-python-print-flats",
          "absolute-85-nappa-sandals",
          "absolute-40-nappa-laminata-sandals-1",
          "solar-85-nappa-laminata-sandals",
          "solar-65-nappa-laminata-sandals-1",
          "luster-40-nappa-laminata-sandals-1",
          "console-lug-nappa-laminata-flats",
          "dwell-suede-flats",
          "oriel-05-lucertolina-flats",
          "alpha-85-naplack-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss24`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS24_Image_3_Desktop_2000x.jpg?v=1707272415",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/SS24_Image_3_Mobile_850x.jpg?v=1707272413",
        }}
        altText="Spring/Summer '24 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss24}>
        {allSkus.filter(sku => [
          "ardor-55-naplack-sandals",
          "midnight-05-naplack-sandals",
          "reverse-wedge-90-nappa-sandals",
          "reverse-sandal-75-nappa-sandals",
          "flatline-10-nappa-laminata-flats",
          "telegraphic-65-suede-sandals",
          "dusk-05-suede-flats",
          "dream-catcher-90-suede-sandals",
          "dream-maker-10-suede-flats",
          "solstice-65-suede-sandals",
          "solstice-10-nappa-laminata-flats",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-ss2`}
            />
          ))}
      </ul>
    </div>
  )
}

export default SpringSummer24

export const query = graphql`
    query SpringSummer24Query {
        allProductStyle(filter: {handle: {in: [
            "lure-90-nappa-sandals",
            "forever-85-nappa-pumps",
            "siren-85-nappa-pvc-pumps",
            "stow-10-nappa-flats",
            "stow-stud-10-nappa-flats-1",
            "balanced-10-nappa-flats-1",
            "balanced-stud-10-nappa-flats",
            "desire-65-nappa-pumps-1",
            "futura-65-nappa-sandals",
            "solitude-65-nappa-laminata-sandals-1",
            "stares-35-python-print-sandals",
            "cougar-10-python-print-flats",
            "absolute-85-nappa-sandals",
            "absolute-40-nappa-laminata-sandals-1",
            "solar-85-nappa-laminata-sandals",
            "solar-65-nappa-laminata-sandals-1",
            "luster-40-nappa-laminata-sandals-1",
            "console-lug-nappa-laminata-flats",
            "dwell-suede-flats",
            "oriel-05-lucertolina-flats",
            "alpha-85-naplack-sandals",
            "ardor-55-naplack-sandals",
            "midnight-05-naplack-sandals",
            "reverse-wedge-90-nappa-sandals",
            "reverse-sandal-75-nappa-sandals",
            "flatline-10-nappa-laminata-flats",
            "telegraphic-65-suede-sandals",
            "dusk-05-suede-flats",
            "dream-catcher-90-suede-sandals",
            "dream-maker-10-suede-flats",
            "solstice-65-suede-sandals",
            "solstice-10-nappa-laminata-flats",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
